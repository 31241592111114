import { Environment } from '@interfaces/environments';

export const environment: Environment = {
  production: true,
  network: 'bsc',
  chainId: 56,
  infuraId: '',
  pinata_api_key: '',
  pinata_api_secret_key: '',
  projectId: '1fdd3da123fbd73d63ac62c32ae8318d',
  api: 'https://nld007.backend.ica.ne2.nexxyolabs.net',
  apiGraphQL: 'https://gateway-arbitrum.network.thegraph.com/api/47da6de9725cf2d81c58480d90762fca/subgraphs/id/DSvvGSgcyoXmoSHyxo7Hce1QtjXtoV8maU8rD6wGZ6oo',
  defaultLang: 'en',
  mobileProvider: 'https://data-seed-prebsc-1-s1.binance.org:8545/',
  mailchimp: {
    apiUrl: 'https://nld004.backend.mailer.webapi.dev-nexxyolabs.net/api/mailchimp/requests/subscribe',
    apiKey: 'gMx6Hi9LqGZ97NhTugUiXLTYiG+82g',
    project: 'Hashapon'
  }
};
